
















import { Inject, Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class SkeletonPreloader extends Vue {
  @Inject() readonly theme!: string;

  @Prop({ type: String, required: false, default: "list-item-avatar-two-line" })
  type!: string;
  @Prop({ type: Boolean, default: false }) tile!: boolean;
  @Prop({ type: Boolean, default: false }) boilerplate!: boolean;
  @Prop({ type: String, default: "30vh" }) height!: string;
  @Prop({ type: String, default: "100vh" }) width!: string;
  @Prop({ type: Number, default: 1 }) count!: number;
  @Prop({ default: "block" }) display!: string;
  @Prop({ default: "" }) classes!: string;
}
